.form-control {
  margin: 5px;
}

.contact-us {
  background-image: linear-gradient(0.55turn, #4643e4 0%, #ffffff 100%);
}

.contact-header {
  font-weight: 700;
  font-style: normal;
}

.contact-text {
  font-weight: 400;
  font-style: normal;
  color: white;
  text-decoration: none;
  font-size: larger;
}

.interest-text {
  font-weight: 300;
  font-style: normal;
}

.message-text {
  font-weight: 600;
  font-style: normal;
}

@media screen and (max-width: 768px) {
  .contact-text {
    font-size: 16px;
  }
}
