@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
  /* font-family: "Poppins", sans-serif; */
  /* font-weight: 100; */
  /* font-style: normal; */
}

body, * {
  font-family: "Poppins", sans-serif !important;
}
