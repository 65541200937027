.feature-card {
    background-color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 2px 4px 5px 1px darkgray;
}

.feature-title {
    font-weight: 600;
    font-style: normal;
}

.feature-text {
    font-weight: 300;
    font-style: normal;
}
