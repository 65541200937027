.reviews {
  padding: 10px;
}

.quote {
  padding: 40px 80px;
  /* font-size: 14px; */
}

.p-quote {
  font-size: 14px;
}

.carousel-card {
  background-color: #F6F5FB;
  border-radius: 20px;
  min-height: 250px;

}

.blockquote-footer {
  padding-bottom: 30px;
}
