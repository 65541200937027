.navbar {
  /* background-color: rgba(11,38,184); */
  --bs-navbar-padding-x: 0 !important;
  --bs-navbar-padding-y: 0 !important;
}

.navbar-toggler {
  background-color: white !important;
  margin-right: 10px;
}

.nav-header {
  color: white;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.175);
  z-index: 100;
  padding-right: 1em;
}

.navbar-collapse {
  text-align: center;
}

.navbar-item {
  color: #4643e4 !important;
  font-weight: bold !important;
  font-size: 20px !important;
  flex-grow: 1 !important;
  text-align: center !important;
  padding: 20px !important;
}

.navbar-logo {
  margin-left: 45px;
  margin-right: 45px;
}

.navbar-nav {
  /* margin-left: 50px !important; */
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  /* align-items: center !important; */
}
