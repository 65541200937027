.header {
  font-weight: 600;
}

.description {
  font-weight: 400;
  font-size: 30px;
}

.reach-out {
  font-weight: 400;
  font-style: normal;
}

.product-card {
  width: 100%;
}

.product-title {
  font-weight: 600;
  font-size: 1.25rem;
}

.product-text {
  font-weight: 300;
}

.bg-svg-container {
  background-image: url('../images/icons/circles.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.cad-image {
  width: 60%;
  margin: 0 auto;
  right: 30px;
  bottom: 70px;
}